<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="沟通记录"/>
        <div>

        </div>
        <el-form :model="form" :rules="rules" ref="form" label-width="200px" class="form">
            <el-row>
                <el-col :span="8">
                    <el-form-item label="院区" prop="hospital_id">
                        {{form.hospital.name}}
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="科室" prop="department_id">
                        {{form.department.name}}
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="医生" prop="doctor_id">
                        {{form.doctor.name}}
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="患者姓名" prop="patient">
                        {{form.patient}}
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="身份证号" prop="idnum">
                        {{form.idnum}}
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="联系电话" prop="phone">
                        {{form.phone}}
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="就诊时间" prop="date">
                        {{form.date}} {{ form.time}}
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="订单时间" prop="time">
                        {{form.createAt}}
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                </el-col>
            </el-row>
            <el-form-item label="既往病史简述" prop="desc">
                <el-input type="textarea" v-model="form.desc"></el-input>
            </el-form-item>
            <el-form-item label="是否提前做体检或诊疗预案" prop="plan">
                <el-input type="textarea" v-model="form.plan" :rows="10"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('form')">保存</el-button>
                <el-button @click="resetForm('form')">重置</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    hospital: {},
                    department: {},
                    doctor: {},
                },
                rules: {
                    desc: [
                        {required: true, message: '请输入分时设置', trigger: 'blur'},
                    ],
                }
            };
        },
        computed: {
            title(){
                let s = this.$route.query.s
                if (s) {
                    return s
                }
                return '开始预约'
            }
        },
        methods: {
            async submitForm() {
                console.log(this.form)
                const valid = await this.$refs.form.validate().then(res => res).catch(error => error);
                if (!valid) return
                let method = this.form.id ? 'put' : 'post'
                let url = this.form.id ? `/mingde/appoint/${this.form.id}` : `/mingde/appoint/`
                const resp = await this.$http[method](url, this.form)
                if (resp.data.code == 200) {
                    this.$message.success("保存成功！")
                    await this.$router.replace("/main/record/index")
                } else {
                    this.$message.error(resp.data.msg)
                }
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            async getData() {
                let id = this.$route.query.id
                if (id) {
                    const resp = await this.$http.get(`/mingde/appoint/${id}`)
                    this.form = resp.data.data
                }
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .form {
        margin-top: 20px;
    }
</style>
